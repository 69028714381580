var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "theme-mode-warp" },
    [
      _c("card-header", {
        attrs: {
          title: _vm.menuData.name,
          edit: _vm.edit,
          menuData: _vm.menuData,
        },
        on: { deleteData: _vm.deleteData, editTheme: _vm.editTheme },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    !_vm.edit
                      ? _c("en-icon", {
                          staticClass: "header-right-arrow",
                          attrs: {
                            name: "zuocecaidanlan-shouqi",
                            size: "22px",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toThemeDefault.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !_vm.hasCalendar
        ? _c("theme-default", {
            key: _vm.menuData.refData.viewId,
            staticClass: "theme-default",
            attrs: { menuData: _vm.menuData, edit: _vm.edit },
          })
        : _c("theme-calendar", {
            key: _vm.menuData.refData.viewId,
            staticClass: "theme-default",
            attrs: { menuData: _vm.menuData, edit: _vm.edit },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }