<!--
 * @Author: wuqi
 * @Date: 2021-08-05 11:12:14
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-26 10:31:28
 * @Description:
-->
<template>
  <div class='theme-mode-warp'>
    <card-header :title="menuData.name" @deleteData="deleteData" :edit="edit" @editTheme='editTheme' :menuData='menuData'>
      <template #right>
        <div>
          <en-icon v-if="!edit" @click.native="toThemeDefault" class="header-right-arrow" name="zuocecaidanlan-shouqi" size="22px"></en-icon>
        </div>
      </template>
    </card-header>
    <theme-default class='theme-default' v-if="!hasCalendar" :menuData='menuData' :edit='edit' :key="menuData.refData.viewId"></theme-default>
    <theme-calendar class='theme-default' v-else :menuData='menuData' :edit='edit' :key="menuData.refData.viewId"></theme-calendar>
  </div>
</template>

<script>
import themeDefault from "@/views/themeSet/viewDefault";
import themeCalendar from "@/views/themeSet/viewCalendar";

import { themeCommonService } from "@/api";
import CardHeader from "./card-header";

export default {
  name: "themeMode",
  data() {
    return {
      hasCalendar: false
    };
  },
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    }
  },
  components: {
    themeDefault,
    CardHeader,
    themeCalendar
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.queryUserViewList();
  },
  methods: {
    editTheme() {
      this.$emit("editTheme");
    },
    deleteData() {
      this.$emit("deleteData");
    },
    toThemeDefault() {
      this.$router.push({
        path: this.hasCalendar ? "/themeSet/viewCalendar" : "/themeSet/viewDefault",
        query: { templateId: this.menuData.refData.id, viewId: this.menuData.refData.viewId, templateName: this.menuData.refData.name }
});
    },
    /**
     * @description: 查询视图数据
     */
    async queryUserViewList() {
      const rsp = await themeCommonService.queryUserViewList({ templateId: this.menuData?.refData?.id });
      const obj = rsp.filter((a) => a.type.toString() === "2");
      this.hasCalendar = !!obj?.length;
    }
  }
};
</script>

<style lang='scss' scoped>
.theme-mode-warp{
  height: 100%;
  .theme-default{
    height: calc(100% - 51px);
    overflow: auto;
  }
}

</style>
